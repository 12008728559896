<template>
  <Tabs value="name1" type="card">
    <TabPane label="基本信息" name="name1">
      <Form
        :model="formData"
        :label-colon="true"
        style="margin: 0 10px"
        :label-width="90"
        :disabled="true"
      >
        <FormItem label="标题">
          <span slot="label" class="validate">标题</span>
          <Input v-model="formData.title" style="width: 50%"></Input>
        </FormItem>
        <FormItem label="公布时间">
          <span slot="label" class="validate">公布时间</span>
          <DatePicker
            :options="dateOption"
            :transfer="true"
            v-model="formData.time"
            type="datetimerange"
            style="width: 400px"
            placeholder="请选择公布开始时间和结束时间"
          ></DatePicker>
          <Tooltip placement="top" :delay="500">
            <Icon type="md-help-circle" style="margin: 10px"></Icon>
            <div slot="content">
              <p>到了公共开始时间，终端上才可查看到；</p>
              <p>如果后台发布了，没有到开始时间，终端上也查看不到</p>
            </div>
          </Tooltip>
        </FormItem>
        <FormItem label="发布终端">
          <span slot="label" class="validate">发布终端</span>
          <CheckboxGroup v-model="formData.terminal">
            <Checkbox label="0">全部</Checkbox>
            <Checkbox label="1">TV端</Checkbox>
            <Checkbox label="2">PC端</Checkbox>
            <Checkbox label="3">App</Checkbox>
            <Checkbox label="4">小程序</Checkbox>
            <Checkbox label="5">公众号</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <FormItem label="发布范围">
          <span slot="label" class="validate">发布范围</span>
          <Select
            v-model="city"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择市"
            multiple
            filterable
            @on-change="changeCity"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in cityList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            v-model="region"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择区"
            :disabled="regionDisabled"
            multiple
            filterable
            @on-change="changeRegion"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in regionList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            v-model="stree"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择街道/乡镇"
            :disabled="streeDisabled"
            multiple
            filterable
            @on-change="changeStree"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in streeList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            v-model="project"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择社区"
            :disabled="projectDisabled"
            multiple
            filterable
          >
            <Option
              :value="item.value"
              v-for="(item, index) in projectList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
        </FormItem>
      </Form>
    </TabPane>
    <TabPane label="弹窗设置" name="name2">
      <Form
        :model="formData"
        :label-colon="true"
        style="margin: 0 10px"
        :label-width="90"
        :rules="validatorForm"
        ref="modelSetting"
        :disabled="true"
      >
        <FormItem label="说明">
          <Input
            type="textarea"
            v-model="formData.description"
            :maxlength="200"
            :rows="4"
            :show-word-limit="true"
          ></Input>
        </FormItem>
        <FormItem label="弹窗页面" prop="propPageCode">
          <Input v-model="formData.propPageCode"></Input>
        </FormItem>
        <FormItem label="弹窗频率" prop="propFrequency">
          <Select v-model="formData.propFrequency" style="width: 200px">
            <Option value="0">首次进入</Option>
            <Option value="1">每天首次</Option>
            <Option value="2">每月首次</Option>
          </Select>
        </FormItem>
        <FormItem label="是否跳转" prop="propSkip">
          <RadioGroup v-model="formData.propSkip">
            <Radio label="0">否</Radio>
            <Radio label="1">是</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="按钮名称"
          prop="buttonName"
          v-if="formData.propSkip == '1'"
        >
          <Input v-model="formData.buttonName"></Input>
        </FormItem>
        <FormItem
          label="跳转链接"
          prop="skipLink"
          v-if="formData.propSkip == '1'"
        >
          <Input v-model="formData.skipLink"></Input>
        </FormItem>
        <FormItem label="背景图">
          <img
            ref="image"
            :src="formData.photo"
            alt=""
            width="200"
            style="display: block; margin-bottom: 10px"
          />
        </FormItem>
      </Form>
    </TabPane>
    <LiefengModal
      title="驳回原因"
      :value="rejectStatus"
      @input="rejectStatusFn"
      width="500px"
    >
      <template v-slot:contentarea>
          <Input v-model.trim="reason"></Input>
      </template>
      <template v-slot:toolsbar>
        <Button
          type="info"
          @click="
            cancelModalTip = true;
            rejectStatusFn(false);
          "
          style="margin-right: 10px"
          >取消</Button
        >
        <Button type="primary" @click="publish"
          >确定</Button
        >
      </template>
    </LiefengModal>
  </Tabs>
</template>

<script>
import LiefengModal from '@/components/LiefengModal'
export default {
  props: ["addChangeIden", "infoId", "menuCodeObj", "infoStatus"],
  data() {
    return {
      formData: {
        dataScopeIdList: [],
        time: [],
        terminal: [],
        buttonName: "前往",
      },
      dataScopeIdList: [], //发布范围列表
      dateOption: {
        shortcuts: [
          //公布时间配置项
          {
            text: "1天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
              return [start, end];
            },
          },
          {
            text: "7天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "30天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "365天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 365);
              return [start, end];
            },
          },
        ],
      },
      validatorForm: {
        propPageCode: [
          { required: true, message: "请填写页面编码", trigger: "blur" },
        ],
        propFrequency: [
          { required: true, message: "请选择弹窗频率", trigger: "change" },
        ],
        propSkip: [
          { required: true, message: "请选择是否跳转", trigger: "change" },
        ],
        buttonName: [
          { required: true, message: "请填写按钮名称", trigger: "blur" },
        ],
        skipLink: [
          { required: true, message: "请填写跳转链接", trigger: "blur" },
        ],
      },

      // 上传图片
      action: "",
      file: "",
      imageName: "",
      imagePath: "",
      uploadData: {},
      city: [],
      region: [],
      stree: [],
      project: [],
      cityList: [],
      regionList: [],
      streeList: [],
      projectList: [],

      regionDisabled: true,
      streeDisabled: true,
      projectDisabled: true,

      // 驳回
      reason: "",
      rejectStatus: false,
      cancelModalTip: true,
    };
  },
  methods: {
    // 驳回
    rejectStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.reason = '';
            this.rejectStatus = status;
          },
        });
      } else {
        this.rejectStatus = status;
      }
    },
    reject() {
        this.rejectStatus = true;
    },
    // 发布
    publish() {
        if(this.rejectStatus && !this.reason) {
          this.$Message.error({
            background: true,
            content: "请输入驳回原因"
          })
          return
        }
      this.$post("/info/api/pc/information/v2/change", {
        status:
           this.rejectStatus
            ? "2"
            :
            this.infoStatus == "待审核"
            ? '3'
            : this.infoStatus == "待发布"
            ? "4"
            : this.infoStatus == "待归档"
            ? "5"
            : "",
        recommend: "0",
        sort: "100",
        id: this.infoId,
        staffName: parent.vue.loginInfo.userinfo.realName,
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        oemCode: parent.vue.oemInfo.oemCode,
        businessType: this.menuCodeObj.businessType,
        functionType: this.menuCodeObj.functionType,
        terminal: "2",
        reason: this.reason,
      }).then((res) => {
        if (res.code == 200) {
          this.$Message.success({
            background: true,
            content: "操作成功",
          });
          this.cancelModalTip = false;
          this.$emit("saveSuccess");
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    // 城市选择
    changeCity(code) {
      if (code.length >= 2) {
        this.regionList = [];
        this.streeList = [];
        this.projectList = [];
        this.region = [];
        this.stree = [];
        this.project = [];
        this.regionDisabled = true;
        this.streeDisabled = true;
        this.projectDisabled = true;
      } else if (code.length == 1) {
        this.regionDisabled = false;
        let arrCode = code[code.length - 1].split("-");
        this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region");
      }
    },
    // 区选择
    changeRegion(code) {
      if (code.length >= 2) {
        this.streeList = [];
        this.projectList = [];
        this.stree = [];
        this.project = [];
        this.streeDisabled = true;
        this.projectDisabled = true;
      } else if (code.length == 1) {
        this.streeDisabled = false;
        let arrCode = code[code.length - 1].split("-");
        this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree");
      }
    },
    // 街道选择
    changeStree(code) {
      if (code.length >= 2) {
        this.projectList = [];
        this.project = [];
        this.projectDisabled = true;
      } else if (code.length == 1) {
        this.projectDisabled = false;
        let arrCode = code[code.length - 1].split("-");
        this.getPulishData(
          arrCode[arrCode.length - 1],
          "projectList",
          "project"
        );
      }
    },
    // 获取发布范围列表
    getPulishData(code, list, model) {
      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          this[list] = res.dataList.map((item) => {
            return {
              value: item.orgPath,
              label: item.orgName,
              orgLevel: item.orgLevel,
            };
          });
          if (res.dataList.length == "1") {
            this[model] = [res.dataList[0].orgPath];
          }
        }
      });
    },
  },
  async created() {
    //获取信息类型subType
    this.$get("/voteapi/api/pc/menu/getMenuParam", {
      columnCode: this.$core.getUrlParam("menuId"),
      orgCode: parent.vue.loginInfo.userinfo.orgCode,
    }).then((res) => {
      this.formData.subType = res[0] ? res[0].value : "";
    });
    await this.getPulishData("44", "cityList", "city");

    // 修改回显数据
    if (this.infoId) {
      this.$get("/datamsg/api/pc/information/v2/queryById", {
        infoId: this.infoId,
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        oemCode: parent.vue.oemInfo.oemCode,
        functionType: this.menuCodeObj.functionType,
        terminal: "2",
      }).then((res) => {
        if (res.code == 200 && res.data) {
          this.formData = {
            title: res.data.title,
            time: [res.data.startTime, res.data.endTime],
            terminal: res.data.terminal.split(","),
            description: res.data.description,
            buttonName: res.data.prop.buttonName,
            photo: res.data.photo,
            propPageCode: res.data.prop.propPageCode,
            propFrequency: res.data.prop.propFrequency,
            propSkip: res.data.prop.propSkip,
            skipLink: res.data.prop.skipLink,
          };
          if (res.data.dataScopeList.length == 1) {
            res.data.dataScopeList.map((item) => {
              let arr = item.split("-");
              switch (arr.length) {
                case 2:
                  this.city = item;
                  break;
                case 3:
                  this.city = arr[0] + "-" + arr[1];
                  this.region = item;
                  break;
                case 4:
                  this.city = arr[0] + "-" + arr[1];
                  this.region = arr[0] + "-" + arr[1] + "-" + arr[2];
                  this.stree = item;
                  break;
                case 5:
                  this.city = arr[0] + "-" + arr[1];
                  this.region = arr[0] + "-" + arr[1] + "-" + arr[2];
                  this.stree =
                    arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3];
                  this.project = item;
                  break;
              }
            });
          } else if (res.data.dataScopeList.length > 1) {
            res.data.dataScopeList.map((item) => {
              let arr = item.split("-");
              switch (arr.length) {
                case 2:
                  this.city.push(item);
                  break;
                case 3:
                  this.city.push(arr[0] + "-" + arr[1]);
                  this.region.push(item);
                  break;
                case 4:
                  this.city.push(arr[0] + "-" + arr[1]);
                  this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2]);
                  this.stree.push(item);
                  break;
                case 5:
                  this.city.push(arr[0] + "-" + arr[1]);
                  this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2]);
                  this.stree.push(
                    arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]
                  );
                  this.project.push(item);
                  break;
              }
            });
            this.city = Array.from(new Set(this.city));
            this.region = Array.from(new Set(this.region));
            this.stree = Array.from(new Set(this.stree));
            this.project = Array.from(new Set(this.project));
          }
        }
      });
    }
  },
  components: {
      LiefengModal
  }
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-tooltip-inner {
  max-width: 100%;
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-input[disabled], /deep/fieldset[disabled] .ivu-input,/deep/.ivu-checkbox-disabled .ivu-checkbox-inner,/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner,/deep/.ivu-select-disabled .ivu-select-selection,/deep/.ivu-radio-disabled .ivu-radio-inner {
    background-color: #fff;
    color: #333;
}
</style>